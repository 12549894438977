@import '@hiredigital/ui/variables';

.listContainer {
  // background: $blue-5;
  padding-top: 30px;
  min-height: 500px;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: $content-width;
  margin: 0px auto;
  padding: 0;
  box-sizing: border-box;

  @include for-size(tablet-landscape-down) {
    // padding: 15px 20px;
    box-sizing: border-box;
  }
}

.pagination {
  cursor: pointer;
  font-size: 16px;
  margin: 10px auto 20px;
  padding: 15px 25px;
  background-color: $primary-600;
  color: $white;
  border-radius: $border-radius;
  transition: transform 0.2s $cubic, box-shadow 0.2s $cubic;
  box-shadow: $shadow-20;

  &:hover {
    background-color: $primary-500;
    transform: translateY(-3px);
    box-shadow: $shadow-30;
  }

  &:active {
    background-color: $primary-700;
    transform: translateY(-3px);
  }
}
