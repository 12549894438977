@import '@hiredigital/ui/variables';

.gridContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: $content-width + 40px;
  padding: 50px 0;

  box-sizing: border-box;
  margin: auto;

  @include for-size(tablet-portrait-down) {
    flex-direction: column-reverse;
  }
}

.gridItem {
  // width: 100%;
  padding: 0 20px;
  overflow: hidden;
  position: relative;
  margin: auto 0;
  max-width: 576px;
}

.heading {
  font-size: $size-9;
  font-weight: $fw-2;
  line-height: $line-height-10;
  text-align: left;
  color: $neutral-800;
  margin-bottom: 16px;
  white-space: pre-line;

  @include for-size(tablet-landscape-down) {
    font-size: $size-7;
    line-height: $line-height-8;
  }
}

.description {
  font-size: $size-5;
  line-height: $line-height-5;
  text-align: left;
  color: $neutral-500;
  margin-bottom: 16px;
}

.buttons {
  display: flex;
  @include for-size(tablet-landscape-down) {
    flex-direction: column;

    a.button {
      margin: 5px 0;

      & ~ & {
        margin-left: 0;
      }
    }
  }
}

.imagesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 496px; // empirical
  width: 554px; // empirical
  margin-left: auto;
  overflow: hidden;
  flex-wrap: wrap;

  @include for-size(tablet-portrait-down) {
    height: auto;
    padding: 20px 0;
    width: 100%;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;

    // height: 200px;
    flex-wrap: nowrap;
  }
}

.image {
  margin: 2px;
  @include for-size(tablet-portrait-down) {
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    height: 140px !important;
    width: 200px !important;
    margin-left: 0px;
  }
}
